import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "./404.scss"

const NotFoundPage = ({ intl, location }) => {
  const data = useStaticQuery(graphql`
    query {
      pageNotFound: file(relativePath: { eq: "elephant.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout menuSelector={location?.state?.menuSelector || 1}>
      <SEO title="404: Not found" />
      <div className="page-404">
        <Img
          className="page-404-image"
          fluid={data.pageNotFound.childImageSharp.fluid}
        />
        <div className="page-404-text">
          {intl.formatMessage({ id: "page-not-found" })}
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(NotFoundPage)
